<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Edit Item" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Name *"
                  v-model="name"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.name"
                  :error="!!errors.name"
                  :error-messages="errors.name"
                />
                <va-input
                  label="Year *"
                  v-model="year"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.year"
                  :error="!!errors.year"
                  :error-messages="errors.year"
                />
                <va-card class="mb-3" title="File *">
                  <div v-if="fileExisting">
                    <a style="display: block;" target="_blank" :href="fileExisting">
                      <p>{{fileExisting}}</p>
                    </a>
                    <div><va-button small color="danger" @click="deleteFile()">Видалити</va-button></div>
                  </div>
                  <span>Завантажити новий файл</span>
                  <va-file-upload
                    @input="delete errors.file"
                    dropzone
                    v-model="file"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.img" style="color: red;">{{ this.errors.file[0] }}</div>
                  <va-button @click="uploadFile(file[0])">Завантажити</va-button>
                </va-card>
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {

      // key: '',
      name: '',
      year: '',
      test: [],

      file: [],
      fileId: '',
      newFile: '',
      fileExisting: '',
      published: false,

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.fetch()
  },
  methods: {
    submit: function () {
      const data = {
        // key: this.key,
        file: this.newFile,
        name: this.name,
        year: this.year,
        published: !!this.published,
      }

      if (this.$route.params.id) { // update
        axios.put(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/reports/${this.$route.params.id}`, data)
          .then(response => {
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/reports`, data)
          .then(response => {
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$route.params.id) {
        axios.get(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/reports/${this.$route.params.id}`)
          .then(response => {
            // this.key = response.data.key
            this.published = !!response.data.published
            this.name = response.data.name
            this.year = response.data.year
            this.fileExisting = process.env.VUE_APP_BGK_URL + response.data.file
            this.newFile = response.data.file
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
            this.$router.push({ name: 'bgk-page-report' })
          })
      }
    },
    async deleteFile () {
      this.fileExisting = ''
      this.newFile = null
    },
    uploadFile (file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.newFile = response.data.patch
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteVideo () {
      this.videoExisting = ''
      this.videoNew = null
    },
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
